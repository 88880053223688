<template>
  <div class="container px-3 py-4" v-if="isDoctor">
    <div class="row">
      <div class="col-12">
        <div class="py-1 align-items-center d-none d-lg-flex">
          <span class="page-title">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20.8054 7.62288L20.183 6.54279C19.6564 5.62887 18.4895 5.31359 17.5743 5.83798V5.83798C17.1387 6.09461 16.6189 6.16742 16.1295 6.04035C15.6401 5.91329 15.2214 5.59678 14.9656 5.16064C14.8011 4.88342 14.7127 4.56766 14.7093 4.24531V4.24531C14.7242 3.72849 14.5292 3.22767 14.1688 2.85694C13.8084 2.4862 13.3133 2.27713 12.7963 2.27734H11.5423C11.0357 2.27734 10.5501 2.47918 10.1928 2.83821C9.83547 3.19724 9.63595 3.68386 9.63839 4.19039V4.19039C9.62338 5.23619 8.77126 6.07608 7.72535 6.07597C7.40299 6.07262 7.08724 5.98421 6.81001 5.81968V5.81968C5.89484 5.29528 4.72789 5.61056 4.20132 6.52448L3.53313 7.62288C3.00719 8.53566 3.31818 9.70187 4.22878 10.2316V10.2316C4.82068 10.5733 5.18531 11.2049 5.18531 11.8883C5.18531 12.5718 4.82068 13.2033 4.22878 13.5451V13.5451C3.31934 14.0712 3.00801 15.2346 3.53313 16.1446V16.1446L4.1647 17.2339C4.41143 17.679 4.82538 18.0076 5.31497 18.1467C5.80456 18.2859 6.32942 18.2242 6.7734 17.9753V17.9753C7.20986 17.7206 7.72997 17.6508 8.21812 17.7815C8.70627 17.9121 9.12201 18.2323 9.37294 18.6709C9.53748 18.9482 9.62589 19.2639 9.62924 19.5863V19.5863C9.62924 20.6428 10.4857 21.4993 11.5423 21.4993H12.7963C13.8493 21.4993 14.7043 20.6484 14.7093 19.5954V19.5954C14.7069 19.0873 14.9076 18.5993 15.2669 18.24C15.6262 17.8807 16.1143 17.6799 16.6224 17.6824C16.944 17.691 17.2584 17.779 17.5377 17.9387V17.9387C18.4505 18.4646 19.6167 18.1536 20.1464 17.243V17.243L20.8054 16.1446C21.0605 15.7068 21.1305 15.1853 21 14.6956C20.8694 14.206 20.549 13.7886 20.1098 13.5359V13.5359C19.6705 13.2832 19.3502 12.8658 19.2196 12.3762C19.089 11.8866 19.159 11.3651 19.4141 10.9272C19.58 10.6376 19.8202 10.3975 20.1098 10.2316V10.2316C21.0149 9.70216 21.3252 8.54276 20.8054 7.63203V7.63203V7.62288Z"
                stroke="#20419B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <circle
                cx="12.1752"
                cy="11.8881"
                r="2.63616"
                stroke="#20419B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {{ $t("title.setting") }}
          </span>
        </div>
      </div>
      <div class="col-sm-12">
        <nav class="navbar navbar-expand-lg navbar-light border-0 bg-white">
          <a class="navbar-brand d-lg-none" href="javascript:;">
            <span class="txt-pri page-title">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20.8054 7.62288L20.183 6.54279C19.6564 5.62887 18.4895 5.31359 17.5743 5.83798V5.83798C17.1387 6.09461 16.6189 6.16742 16.1295 6.04035C15.6401 5.91329 15.2214 5.59678 14.9656 5.16064C14.8011 4.88342 14.7127 4.56766 14.7093 4.24531V4.24531C14.7242 3.72849 14.5292 3.22767 14.1688 2.85694C13.8084 2.4862 13.3133 2.27713 12.7963 2.27734H11.5423C11.0357 2.27734 10.5501 2.47918 10.1928 2.83821C9.83547 3.19724 9.63595 3.68386 9.63839 4.19039V4.19039C9.62338 5.23619 8.77126 6.07608 7.72535 6.07597C7.40299 6.07262 7.08724 5.98421 6.81001 5.81968V5.81968C5.89484 5.29528 4.72789 5.61056 4.20132 6.52448L3.53313 7.62288C3.00719 8.53566 3.31818 9.70187 4.22878 10.2316V10.2316C4.82068 10.5733 5.18531 11.2049 5.18531 11.8883C5.18531 12.5718 4.82068 13.2033 4.22878 13.5451V13.5451C3.31934 14.0712 3.00801 15.2346 3.53313 16.1446V16.1446L4.1647 17.2339C4.41143 17.679 4.82538 18.0076 5.31497 18.1467C5.80456 18.2859 6.32942 18.2242 6.7734 17.9753V17.9753C7.20986 17.7206 7.72997 17.6508 8.21812 17.7815C8.70627 17.9121 9.12201 18.2323 9.37294 18.6709C9.53748 18.9482 9.62589 19.2639 9.62924 19.5863V19.5863C9.62924 20.6428 10.4857 21.4993 11.5423 21.4993H12.7963C13.8493 21.4993 14.7043 20.6484 14.7093 19.5954V19.5954C14.7069 19.0873 14.9076 18.5993 15.2669 18.24C15.6262 17.8807 16.1143 17.6799 16.6224 17.6824C16.944 17.691 17.2584 17.779 17.5377 17.9387V17.9387C18.4505 18.4646 19.6167 18.1536 20.1464 17.243V17.243L20.8054 16.1446C21.0605 15.7068 21.1305 15.1853 21 14.6956C20.8694 14.206 20.549 13.7886 20.1098 13.5359V13.5359C19.6705 13.2832 19.3502 12.8658 19.2196 12.3762C19.089 11.8866 19.159 11.3651 19.4141 10.9272C19.58 10.6376 19.8202 10.3975 20.1098 10.2316V10.2316C21.0149 9.70216 21.3252 8.54276 20.8054 7.63203V7.63203V7.62288Z"
                  stroke="#20419B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <circle
                  cx="12.1752"
                  cy="11.8881"
                  r="2.63616"
                  stroke="#20419B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {{ $t("title.setting") }}
            </span>
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item" :class="type == 'info' ? 'active' : ''">
                <a
                  href="javascript:;"
                  class="nav-link"
                  @click="changeType('info')"
                  >{{ $t("personal_settings.lbl_personal_information") }}</a
                >
              </li>
              <!-- <li class="nav-item" :class="type == 'worktimes' ? 'active' : ''">
                <a
                  href="javascript:;"
                  class="nav-link"
                  @click="changeType('worktimes')"
                  >Lịch làm việc
                </a>
              </li>-->
              <li
                class="nav-item"
                :class="type == 'clinic-language' ? 'active' : ''"
              >
                <a
                  href="javascript:;"
                  class="nav-link"
                  @click="changeType('clinic-language')"
                  >{{ $t("personal_settings.lbl_language") }}</a
                >
              </li>
              <li class="nav-item" :class="type == 'security' ? 'active' : ''">
                <a
                  href="javascript:;"
                  class="nav-link"
                  @click="changeType('security')"
                  >{{ $t("personal_settings.lbl_account_security") }}</a
                >
              </li>
              <li
                v-if="isShowHodoService"
                class="nav-item"
                :class="type == 'account' ? 'active' : ''"
              >
                <a
                  href="javascript:;"
                  class="nav-link"
                  @click="changeType('account')"
                  >{{ $t("HODO PRO PLATFORM") }}</a
                >
              </li>
              <li class="nav-item" :class="type == 'signature' ? 'active' : ''">
                <a
                  href="javascript:;"
                  class="nav-link"
                  @click="changeType('signature')"
                  >{{ $t("Chữ ký") }}</a
                >
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <div class="bound-border">
      <div class="p-10-10">
        <div v-if="type === 'info'">
          <DoctorSettingInfo></DoctorSettingInfo>
        </div>
        <div
          v-if="isShowHodoService && type === 'account'"
          class="overflow-x-scroll"
        >
          <AccountSetting />
        </div>
        <div v-if="type === 'clinic-language'">
          <div
            role="tabpanel"
            class="tab-pane fade in active show"
            id="select_lang"
          >
            <div class="container pt-4">
              <div
                class="d-flex align-items-center mb-3 cursor-pointer"
                v-for="lang in langs"
                :key="lang.code"
                @click="changeLanguage(lang.code)"
              >
                <input
                  type="radio"
                  :checked="$lang === lang.code"
                  class="mr-2 pe-none"
                />
                <span
                  class="robo-24-500"
                  :class="$lang === lang.code ? 'txt-pri' : 'txt-black'"
                  >{{ lang.name }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div v-if="type === 'clinic-alter'">
          <div role="tabpanel" class="tab-pane fade in active show" id="noti">
            <div class="mt-4 d-flex">
              <div class="switch_box mr-2" @click="onSubscribe()">
                <input
                  type="checkbox"
                  class="switch-button"
                  :checked="isPushEnabled"
                />
              </div>
              <p class="txt-pri robo-16-400">
                {{ isPushEnabled ? "Đã bật thông báo!" : "Bật thông báo" }}
              </p>
            </div>
          </div>
        </div>
        <!-- <div v-if="type === 'worktimes'">
          <Worktime></Worktime>
        </div>-->
        <div v-if="type === 'security'">
          <SecurityInfo></SecurityInfo>
        </div>
        <div v-if="type === 'signature'">
          <DoctorSignature></DoctorSignature>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import {mixinPushNotification} from '../../utils/mixinPushNotification'
import clinicIsDefault from '../../components/Clinic/clinicIsDefault.vue'
import AccountSetting from '../../components/Doctor/AccountSetting.vue'
// import Worktime from '../../components/Doctor/Worktime'
import DoctorSettingInfo from '../../components/Doctor/DoctorSettingInfo.vue'
import SecurityInfo from '../../components/Doctor/SecurityInfo.vue'
import appUtils from '../../utils/appUtils'
import DoctorSignature from '@/pages/DoctorSignature/index.vue'
export default {
  // mixins: [mixinPushNotification],
  name: 'Setting',
  components: {
    clinicIsDefault,
    DoctorSettingInfo,
    SecurityInfo,
    AccountSetting,
    DoctorSignature
  },
  data () {
    return {
      type: null,
      langs: [
        { code: 'vi', name: 'Tiếng Việt' },
        { code: 'en', name: 'English' }
      ]
    }
  },

  computed: {
    isDoctor () {
      let user = appUtils.getLocalUser()
      if (user?.doctor) {
        return user?.doctor
      }
    },
    isShowHodoService () {
      const isOwner =
        this.$globalClinic?.organization?.owner_id === this.$user?.id ||
        this.$globalOrg?.owner_id === this.$user?.id ||
        this.$user?.members?.[0]?.role === 1
      return isOwner && this.$user.is_new_customer === 1
    }
  },
  mounted () {
    // this.initialiseState()
    this.type = this.$route.query.type
      ? this.$route.query.type.toLowerCase()
      : 'info'
    if (!this.$route.query.type) {
      this.changeType('info')
    }
  },
  methods: {
    changeLanguage (lang) {
      if (lang === 'vi') {
        this.$lang = 'vi'
      } else if (lang === 'en') {
        this.$lang = 'en'
      }
    },
    changeType (str_type) {
      let self = this
      self.type = str_type
      self.$router
        .push({ path: '/doctor/setting', query: { type: str_type } })
        .catch(() => {})
    },
    onSubscribe () {
      // if (this.isPushEnabled) {
      //   this.unsubscribe()
      //   console.log('tắting')
      // } else {
      //   this.subscribe()
      //   console.log('mởing')
      // }
    }
  }
}
</script>
<style scoped>
input[type="checkbox"].switch-button {
  font-size: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 1.75em;
  height: 0.75em;
  background: #ddd;
  border-radius: 1.5em;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].switch-button:checked {
  background: #074df2;
}

input[type="checkbox"].switch-button:after {
  position: absolute;
  content: "";
  width: 0.75em;
  height: 0.75em;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  left: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].switch-button:checked:after {
  left: calc(100% - 0.75em);
}
.pe-none {
  pointer-events: none;
}
.nav-item.active a {
  color: #20419b !important;
}
</style>
